import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CustomerConfiguration} from '../domain/customer-configuration';
import {tap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class CustomerConfigurationService {

    baseUrl: string = "api/customer-configurations"

    siteId: string;

    constructor(private http: HttpClient) {
    }

    public save(customerConfiguration: CustomerConfiguration): Observable<CustomerConfiguration> {
        if (customerConfiguration._id) {
            return this.http.put<CustomerConfiguration>(`${this.baseUrl}/${customerConfiguration._id}`, customerConfiguration);
        } else {
            return this.http.post<CustomerConfiguration>(this.baseUrl, customerConfiguration);
        }
    }

    public findAll(): Observable<Array<CustomerConfiguration>> {
        return this.http.get<Array<CustomerConfiguration>>(this.baseUrl);
    }

    public findByCustomer(customerName: string): Observable<CustomerConfiguration> {
        return this.http.get<CustomerConfiguration>(`${this.baseUrl}/${customerName}`)
            .pipe(tap(customerConfigutation => this.siteId = customerConfigutation.siteId));
    }

}
