import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication-service';
import {User} from '../../domain/user';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


    public authentication: any = {name: undefined, password: undefined};

    constructor(private router: Router,
                private authService: AuthenticationService) {
    }

    private getDestination(user: User): string {
        let destination: string = "";
        if (user) {
            if (user.roles && user.roles.indexOf("admin") > -1) {
                destination = "admin/configurations";
            } else {
                destination = `/public/${user.name}`;
            }
        }
        return destination;
    }


    ngOnInit() {
        const user = this.authService.getCurrentUser();
        if (user) {
            this.router.navigate([this.getDestination(user)]);
        } else {
            this.authService.fetchCurrent()
                .subscribe((fetchUser) => {
                    if (fetchUser !== undefined && fetchUser !== null) {
                        this.router.navigate([this.getDestination(fetchUser)]);
                    }
                });
        }
    }

    login() {
        this.authService.authenticate(this.authentication)
            .subscribe((user) => {
                if (user) {
                    this.router.navigate([this.getDestination(user)]);
                } else {
                    console.log("login.error")
                }
            });
    }
}
