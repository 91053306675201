import {Component, Inject, OnInit} from '@angular/core';
import {CustomerConfigurationService} from '../../services/customer-configuration.service';
import {CustomerConfiguration} from '../../domain/customer-configuration';
import {CustomerScreen} from '../../domain/customer-screen';
import {DOCUMENT, LocationStrategy} from '@angular/common';

@Component({
    selector: 'customer-configuration',
    templateUrl: './customer-configuration.component.html',
    styleUrls: ['./customer-configuration.component.scss']
})
export class CustomerConfigurationComponent implements OnInit {

    newCustomer: CustomerConfiguration;
    rootPath: String
    customerConfigurations: Array<CustomerConfiguration>;
    selectedCustomer: CustomerConfiguration;

    constructor(private customerConfigurationService: CustomerConfigurationService,
                @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit(): void {
        this.rootPath = this.document.location.origin
        this.customerConfigurationService.findAll()
            .subscribe(customers => {
                this.customerConfigurations = customers;
                if (customers && customers.length > 0) {
                    this.selectedCustomer = customers[0];
                }
            });
    }

    addCustomer(): void {
        this.newCustomer = {} as CustomerConfiguration;
    }

    saveCustomer(): void {
        this.customerConfigurationService.save(this.newCustomer)
            .subscribe(customer => {
                this.selectedCustomer = customer;
                this.newCustomer = undefined;
            })
    }

    add() {
        if (this.selectedCustomer) {
            this.selectedCustomer.screens.push(new CustomerScreen());
        }
    }

    remove(index: number) {
        if (this.selectedCustomer) {
            this.selectedCustomer.screens.splice(index, 1);
        }
    }

    save() {
        this.customerConfigurationService.save(this.selectedCustomer).subscribe();
    }

    selectedCustomerChanged($event: CustomerConfiguration) {
        this.selectedCustomer = $event;
    }

    selectedCustomerTypeChanged(screen: CustomerScreen, $event: any) {
        screen.type = $event;
    }
}
