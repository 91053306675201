import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication-service';
import {User} from '../../domain/user';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    currentUser: User;

    constructor(private authenticationService: AuthenticationService) {}

    ngOnInit(): void {
        this.authenticationService.fetchCurrent().subscribe(user => this.currentUser = user);
    }

    logout(): void {
        this.authenticationService.logout();
    }

}
