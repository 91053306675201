import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, interval} from 'rxjs';
import {CustomerConfigurationService} from './services/customer-configuration.service';
import {ActivatedRoute} from '@angular/router';
import {CustomerConfiguration} from './domain/customer-configuration';
import {CustomerScreen} from './domain/customer-screen';
import {switchMap, tap} from 'rxjs/operators';


@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public title = 'Niroma';

    private interval : BehaviorSubject<number>;

    public currentScreen: CustomerScreen;
    public customerConfiguration: CustomerConfiguration;

    constructor(private customerConfigurationService: CustomerConfigurationService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        let customer = this.activatedRoute.snapshot.paramMap.get("customer");
        this.customerConfigurationService
            .findByCustomer(customer)
            .subscribe(customerConfiguration => this.loadCustomerDisplay(customerConfiguration));
    }


    private loadCustomerDisplay(customerConfiguration: CustomerConfiguration) {

        // Loads current customer configuration according to the current URL
        this.customerConfiguration = customerConfiguration;
        // Starts at first screen display configured
        this.currentScreen = this.customerConfiguration.screens[0];
        // Initialize behavior subject with current displayed screen delay.
        this.interval = new BehaviorSubject(this.currentScreen.delay);

        this.interval.pipe(
            // Assuming that the value provided by the configuration is in seconds
            switchMap(delayInSeconds => interval(delayInSeconds * 1000)),
            tap(() => {
                let displayedIndex = this.customerConfiguration.screens.indexOf(this.currentScreen);
                displayedIndex++
                if (displayedIndex === this.customerConfiguration.screens.length) {
                    displayedIndex = 0;
                }
                this.currentScreen = this.customerConfiguration.screens[displayedIndex];
                console.log("New delay", this.currentScreen.delay)
                this.interval.next(this.currentScreen.delay);
            })).subscribe();

        //     let secondsCounter = interval(this.currentScreen.delay);
        //     secondsCounter.subscribe(n => {
        //
        //
        //     });
    }

}
