<div class="top-bar">
    <div class="top-bar-left" >
        <div>Utilisateur connecté : {{currentUser?.name}}</div>
        <div>Rôle : {{currentUser?.roles[0]}}</div>

    </div>
    <div class="top-bar-title">
        <h3>Adminstration des installations</h3>
    </div>
    <div class="top-bar-right">
        <div class="top-bar-right-logo"></div>
        <button class="button" (click)="logout()">Déconnexion</button>
    </div>
</div>
