import {GazEmissionSaved} from './gas-emission-saved';
import {Type} from 'class-transformer';

export class EnvironmentBenefits {

  @Type(() => GazEmissionSaved)
  gasEmissionSaved: GazEmissionSaved;

  treesPlanted: number;

  lightBulbs: number;
}
