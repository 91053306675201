<app-header></app-header>

<button (click)="addCustomer()">Nouveau client</button>
<div *ngIf="newCustomer">
    <form>
        <div>
            <label>Nom du client</label>
            <input name="name" [(ngModel)]="newCustomer.customer"/>
        </div>
        <div>
            <label>Site Id :</label>
            <input name="siteId" [(ngModel)]="newCustomer.siteId"/>
        </div>
        <button (click)="saveCustomer()">Ajouer</button>
    </form>
</div>

<div class="main-container content">

    <div class="customer-select">

        <label class="customer-container-label">Client : </label>
        <customer-select class="customer-container-select"
                         [ngModel]="selectedCustomer" name="customer2"
                         (ngModelChange)="selectedCustomerChanged($event)"></customer-select>
    </div>

    <div class="customer-container">

        <h5 class="customer-container-title">Accès à l'application {{selectedCustomer?.customer}} :
            <a target="_blank" [href]="'/#/public/'+ selectedCustomer?.customer">{{rootPath}}{{'/#/public/' + selectedCustomer?.customer}}</a>
        </h5>
        <span></span>

        <h5 class="customer-container-title">Liste des écrans :</h5>

        <div class="customer-container-table" *ngIf="selectedCustomer && selectedCustomer.screens">
            <form novalidate >
                <table>
                    <thead>
                    <tr>
                        <th>Rang</th>
                        <th>Type</th>
                        <th>URL</th>
                        <th>Durée affichage (secondes)</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let screen of selectedCustomer.screens;let index = index">
                        <td>{{index + 1}}</td>
                        <td>
                            <customer-type-select [ngModel]="screen.type"
                                                  (ngModelChange)="selectedCustomerTypeChanged(screen, $event)"
                                                  [name]="'type' + index"></customer-type-select>
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="screen.url" [name]="'url' + index"/>
                        </td>
                        <td>
                            <input type="number" min="0" [(ngModel)]="screen.delay" [name]="'delay' + index"/>
                        </td>
                        <td>
                            <button class="button" (click)="remove(index)">Supprimer</button>
                        </td>
                    </tr>
                    </tbody>
                </table>


<!--                <div class="customer-container-table-line">-->
<!--                    <div class="customer-container-table-column">Rang</div>-->
<!--                    <div class="customer-container-table-column">Type</div>-->
<!--                    <div class="customer-container-table-column">URL</div>-->
<!--                    <div class="customer-container-table-column">Durée affichage (secondes)</div>-->
<!--                    <div class="customer-container-table-column">Actions</div>-->
<!--                </div>-->
<!--                <ng-container *ngIf="selectedCustomer && selectedCustomer.screens">-->
<!--                    <div *ngFor="let screen of selectedCustomer.screens;let index = index"-->
<!--                         class="customer-screen-config customer-container-table-line">-->
<!--                        <div class="customer-container-table-column">-->
<!--                            {{index + 1}}-->
<!--                        </div>-->
<!--                        <div class="customer-container-table-column">-->
<!--                            <customer-type-select [ngModel]="screen.type"-->
<!--                                                  (ngModelChange)="selectedCustomerTypeChanged(screen, $event)"-->
<!--                                                  [name]="'type' + index"></customer-type-select>-->
<!--                        </div>-->
<!--                        <div class="customer-container-table-column" [hidden]="screen.type != 'CUSTOMER'">-->
<!--                            <input type="text" [(ngModel)]="screen.url" [name]="'url' + index"/>-->
<!--                        </div>-->
<!--                        <div class="customer-container-table-column">-->
<!--                            <input type="number" min="0" [(ngModel)]="screen.delay" [name]="'delay' + index"/>-->
<!--                        </div>-->
<!--                        <div class="customer-container-table-column    ">-->
<!--                            c-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </ng-container>-->
                <div class="buttonContainer button-group">
                    <button class="button" (click)="add()">Ajouter</button>
                    <button class="button" (click)="save()" type="submit">Sauvegarder</button>
                </div>
            </form>
        </div>
    </div>
</div>
