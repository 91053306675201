import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CustomerConfigurationComponent} from './views/customer-configuration/customer-configuration.component';
import {AppComponent} from './app.component';
import {LoginComponent} from './views/login/login.component';
import {LoggedInGuard} from './guards/logged-in.guard';
import {LogoutComponent} from './components/logout/logout.component';

const routes: Routes = [
    {
        path: 'login', pathMatch: 'full', component: LoginComponent
    },
    {
        path: 'logout', pathMatch: 'full', component: LogoutComponent
    },
    {
        path: '',
        children: [
            {path: "admin/configurations", component: CustomerConfigurationComponent, canActivate: [LoggedInGuard]},
            {path: 'public/:customer', component: AppComponent},
            {path: '', redirectTo: '/login', pathMatch: 'full'}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
