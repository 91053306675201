import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EnergyDetails} from '../domain/energy-details';
import {HttpClient} from '@angular/common/http';
import {EnvironmentBenefits} from '../domain/environment-benefits';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import * as Moment from 'moment';
import {ENERGY_METER_TYPE_ENUM} from '../domain/energy-meter.type';
import {TIME_UNIT_ENUM, TimeUnit} from '../domain/time-unit.type';
import {PowerFlow} from '../domain/power-flow';
import {CustomerConfigurationService} from './customer-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class SolarEdgeService {

    private baseApi: string = '/api';

    constructor(private httpClient: HttpClient,
                private customerConfigurationService : CustomerConfigurationService) {
    }

    private getEnergyDetails(startTime: Date, endTime: Date, timeUnit?: TimeUnit): Observable<EnergyDetails> {
        return this.httpClient.get<EnergyDetails>(`${this.baseApi}/site/${this.customerConfigurationService.siteId}/energyDetails`,
            {
                params: {
                    meters: ENERGY_METER_TYPE_ENUM.SelfConsumption,
                    timeUnit: timeUnit,
                    startTime: Moment(startTime).toISOString(),
                    endTime: Moment(endTime).toISOString()
                }
            }).pipe(
            map(res => plainToClass(EnergyDetails, res as EnergyDetails)));
    }

    public getEnergyDetailsDaily(): Observable<EnergyDetails> {
        return this.getEnergyDetails(Moment().startOf('day').toDate(), Moment().endOf('day').toDate(), TIME_UNIT_ENUM.DAY);
    }

    public getEnergyDetailsMonthly(): Observable<EnergyDetails> {
        return this.getEnergyDetails(Moment().startOf('month').toDate(), new Date(), TIME_UNIT_ENUM.MONTH);
    }

    public getEnergyDetailsYearly(): Observable<EnergyDetails> {
        return this.getEnergyDetails(Moment().subtract(1, 'year').toDate(), new Date(), TIME_UNIT_ENUM.YEAR)
    }

    public getPowerFlow(): Observable<PowerFlow> {
        return this.httpClient.get<PowerFlow>(`${this.baseApi}/site/${this.customerConfigurationService.siteId}/currentPowerFlow`)
            .pipe(map(res => plainToClass(PowerFlow, res as PowerFlow)));
    }

    public getEnvBenefits(): Observable<EnvironmentBenefits> {
        return this.httpClient.get(`${this.baseApi}/site/${this.customerConfigurationService.siteId}/environmentBenefits`)
            .pipe(map(res => plainToClass(EnvironmentBenefits, res as EnvironmentBenefits)));
    }
}
