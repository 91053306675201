import {EnergyMeter} from './energy-meter';
import {Type} from 'class-transformer';

export class EnergyDetails {

    timeUnit: string;

    unit: string;

    @Type(() => EnergyMeter)
    meters: Array<EnergyMeter>;


    private getSelfConsumption(): EnergyMeter {
        return this.meters && this.meters.find(value => value.type === "SelfConsumption");
    }

    public extractConsumption(): number {
        if (this.unit === "Wh") {
            this.unit = "KWh"
        }
        let selfConsumption = this.getSelfConsumption();
        if (selfConsumption && selfConsumption.values && selfConsumption.values.length > 0) {
            selfConsumption.values.sort((a, b) => {
                if (!a.date) {
                    return 1;
                } else if (!b.date) {
                    return -1;
                } else {
                    return a.date.getMilliseconds() > b.date.getMilliseconds() ? 1 : -1
                }
            });
            return selfConsumption.values[0].value / 1000;
        }
        return 0;
    }
}
