import {Connection} from './connection';
import {ElementData} from './element-data';

export class PowerFlow {
    unit: string;
    connections: Connection;

    GRID: ElementData;
    LOAD: ElementData;
    PV: ElementData;
    EV: ElementData;
    STORAGE: ElementData;

}
