<ng-select
        [name]="'customerSelect'"
        [items]="customerConfigurations"
        [bindLabel]="'customer'"
        [clearable]="false"
        [searchable]="false"
        [disabled]="disabled"
        [ngModel]="customerConfiguration"
        [placeholder]="placeholder"
        (ngModelChange)="onChange($event)">
</ng-select>
