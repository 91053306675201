import {Component, HostListener, Input} from '@angular/core';

@Component({
    selector: 'customer-screen',
    templateUrl: './customer-screen.component.html',
    styleUrls: ['./customer-screen.component.scss']
})
export class CustomerScreenComponent {

    @Input()
    public url: string;

    public innerWidth: number;
    public innerHeight: number;

    @HostListener('window:load', ['$event'])
    onLoad(event) {
        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;
    }

    constructor() {
    }
}
