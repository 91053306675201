<div class="row full-height">

    <form class="row" (submit)="login()">
        <div class="">
            <div class="card-section">
                <div class="grid-x">
                    <div class="medium-24 cell">
                        <input class="form-control has-icon-left" type="text"
                               [(ngModel)]="authentication.name"
                               name="name" required/>
                    </div>
                    <div class="medium-24 cell space-top-15">
                        <input class="form-control has-icon-left" type="password"
                               name="password"
                               [(ngModel)]="authentication.password"
                               required>
                    </div>
                </div>
            </div>
            <div class="card-footer space-top-15">
                <div class="medium-24 cell text-right space-top-4">
                    <button type="submit"
                            class="button btn medium primary has-icon-right">
                        Se connecter
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>



