import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {HeaderComponent} from './components/header/header.component';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {CustomerScreenComponent} from './views/customer-screen/customer-screen.component';
import {CustomerConfigurationComponent} from './views/customer-configuration/customer-configuration.component';
import {FormsModule} from '@angular/forms';
import {AppRootComponent} from './app-root/app-root.component';
import {CustomerSelectComponent} from './views/customer-configuration/customer-select/customer-select.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { SafePipe } from './pipes/safe.pipe';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { CustomerTypeSelectComponent } from './views/customer-configuration/customer-type-select/customer-type-select.component';

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        HeaderComponent,
        CustomerScreenComponent,
        CustomerConfigurationComponent,
        AppRootComponent,
        CustomerSelectComponent,
        SafePipe,
        LoginComponent,
        LogoutComponent,
        CustomerTypeSelectComponent,
        CustomerTypeSelectComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        NgSelectModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'fr-FR'}],
    // providers: [
    //   {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: HttpHeadersInterceptor,
    //     multi: true
    //   }],
    bootstrap: [AppRootComponent]
})
export class AppModule {
}
