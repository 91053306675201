import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomerScreenType, CustomerScreenTypeEnum} from '../../../domain/customer-screen';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomerTypeSelectComponent),
    multi: true
};

@Component({
    selector: 'customer-type-select',
    templateUrl: './customer-type-select.component.html',
    styleUrls: ['./customer-type-select.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class CustomerTypeSelectComponent implements OnInit, ControlValueAccessor {

    @Input()
    public placeholder: string;
    public customerTypes: Array<CustomerScreenTypeEnum> = [CustomerScreenTypeEnum.NIROMA,CustomerScreenTypeEnum.CUSTOMER];
    public customerType: CustomerScreenType;
    public disabled: boolean;

    private onNgChange: (customerType: CustomerScreenType) => void;
    private onNgTouched: () => void;


    ngOnInit() {
    }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(customerType: CustomerScreenType): void {
        this.customerType = customerType;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onChange(customerType: CustomerScreenType): void {
        this.customerType = customerType;
        this.onNgChange(customerType);
    }
}
