import {MeterValue} from './meter-value';
import {Type} from 'class-transformer';

export class EnergyMeter {

  type: string;

  @Type(() => MeterValue)
  values: Array<MeterValue>;
}
