export type CustomerScreenType =
    'CUSTOMER' | 'NIROMA'

export enum CustomerScreenTypeEnum {
    CUSTOMER = 'CUSTOMER',
    NIROMA = 'NIROMA'
}

export const CustomerScreenTypes = [CustomerScreenTypeEnum.CUSTOMER, CustomerScreenTypeEnum.NIROMA]

export class CustomerScreen {

    url: string;
    type: CustomerScreenType;
    delay: number;


    constructor() {
        this.type = CustomerScreenTypeEnum.CUSTOMER
    }
}
