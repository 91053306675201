import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import {Md5} from 'ts-md5/dist/md5';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {plainToClass} from 'class-transformer';
import {User} from '../domain/user';

@Injectable({
    providedIn: "root"
})
export class AuthenticationService {

    private baseUrl = '/api/sessions';

    public userEvents: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

    constructor(private router: Router,
                private http: HttpClient) {
    }

    public authenticate(authentication: any): Observable<User> {
        const md5 = new Md5().appendStr(authentication.password).end();

        const authPayload = {principal: {name: authentication.name, passwordHash: md5}};

        return this.http.post(this.baseUrl, authPayload, {withCredentials: true})
            .pipe(
                map((result: any) => result.principal),
                map(principal => plainToClass(User, principal as Object)),
                tap((user: User) => this.userEvents.next(user)));
    }

    public fetchCurrent(): Observable<User> {
        return this.http.get(this.baseUrl + '/current', {withCredentials: true})
            .pipe(
                map((result: any) => result.principal),
                map(principal => plainToClass(User, principal as Object)),
                tap((user: User) => this.userEvents.next(user)));
    }

    public getCurrentUser(): User {
        return this.userEvents.getValue();
    }

    public logout(): void {
        this.http.delete(this.baseUrl + '/current').subscribe(() => {
            this.userEvents.next(undefined);
            this.router.navigate(['/login']);
        });
    }
}
