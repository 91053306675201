export class GazEmissionSaved {

  units: string;

  co2: number;

  so2: number;

  nox: number
}
