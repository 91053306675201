<div class="dashboard-container">
    <h1>Monitoring de l’installation photovoltaïque </h1>
    <div class="dashboard-container-row">
        <div class="statistics-container">
            <div class="statistics-container-leftColumn">
                <div [class]="getPvIllustration()" class="block pv">
                    <div class="block-header">
                        <h2>Photovoltaïque</h2>
                    </div>
                    <div class="block-content"></div>
                    <p>Energie issue de l'installation photovoltaîque</p>
                    <div class="block-footer">
                        <label>{{powerFlow?.PV.currentPower| number: '1.0-2' }} {{powerFlow?.unit}}</label>
                    </div>

                    <!--                    <div *ngIf="isStorageCharging()" class="arrow between-pv-and-storage start"></div>-->
                    <div *ngIf="isStorageCharging()" class="arrow between-pv-and-storage middle"></div>
                    <!--                    <div *ngIf="isStorageCharging()" class="arrow between-pv-and-storage end"></div>-->
                    <!--                    <div *ngIf="isCommercialLoadActive()" class="arrow between-pv-and-commercial start"></div>-->
                    <div *ngIf="isCommercialLoadActive()" class="arrow between-pv-and-commercial"></div>
                </div>
                <div class="statistics-container-leftColumn-row">
                    <div *ngIf="isVehicleAvailable()" class="block vehicule active">
                        <div class="block-header">
                            <h2>Véhicules électriques</h2>
                        </div>
                        <div class="block-content"></div>
                        <div class="block-footer">
                            <label>{{powerFlow?.STORAGE.currentPower| number: '1.0-2'}} {{powerFlow?.unit}}</label>
                        </div>
                    </div>
                    <div *ngIf="isStorageAvailable()" class="block storage">
                        <div class="block-header">
                            <h2>Batteries</h2>
                        </div>
                        <div [class]="getStorageIllustration()" class="block-content">
                            <img *ngIf="true" class="charging" src="../../../assets/illustrations/storage/Battery_Charging.svg">
                            <div class="storage-charge">
                                {{powerFlow?.STORAGE.chargeLevel | number: '1.0-2'}} %</div>
                        </div>
                        <p>Energie fournie par les batteries</p>
                        <div class="block-footer">
                            <label>{{powerFlow?.STORAGE.currentPower| number: '1.0-2'}} {{powerFlow?.unit}}</label>
                        </div>
                        <div *ngIf="isStorageDischarging()" class="arrow between-storage-and-commercial"></div>
                        <!--                    <div *ngIf="isStorageDischarging()" class="arrow between-storage-and-commercial end"></div>-->
                    </div>
                </div>
            </div>
            <div [class]="getCommercialLoadClass()" class="block load">
                <div class="block-header">
                    <h2>Bâtiment</h2>
                </div>
                <div class="block-content"></div>
                <p>Consommation du bâtiment</p>
                <div class="block-footer">
                    <label>{{powerFlow?.LOAD?.currentPower | number: '1.0-2'}} {{powerFlow?.unit}}</label>
                </div>
            </div>
            <div class="block grid">
                <div class="block-header">
                    <h2>Réseau</h2>
                </div>
                <div class="block-content">
                    <img src="../../../assets/illustrations/grid.svg">
                </div>
                <p>Energie fournie par le réseau</p>
                <div class="block-footer">
                    <label>{{powerFlow?.GRID?.currentPower| number: '1.0-2'}} {{powerFlow?.unit}}</label>
                </div>
                <div *ngIf="isGridActive()" class="arrow between-grid-and-commercial"></div>
            </div>
        </div>
        <div class="environment-container">
            <div class="secondary-block co2">
                <div class="block-header">
                    <h2>Réduction d'émission de co2</h2>
                </div>
                <label class="block-footer">{{environmentBenefits?.gasEmissionSaved?.co2 | number: '1.0-0'}} {{environmentBenefits?.gasEmissionSaved?.units}}</label>
            </div>
            <div class="secondary-block trees">
                <div class="block-header">
                    <h2>Arbres plantés</h2>
                </div>
                <label class="block-footer">{{environmentBenefits?.treesPlanted | number: '1.0-1'}} </label>
            </div>
        </div>
    </div>

    <div class="dashboard-container-row">
        <div class="period-statistics-container">
            <div class="block day">
                <div class="block-header">
                    <h2>Consommation du jour</h2>
                </div>
                <div class="block-footer">
                    <label>{{dayValue | number: '1.0-2'}} <small>{{dayEnergyDetails?.unit}}</small></label>
                </div>
            </div>
            <div class="block month">
                <div class="block-header">
                    <h2>Consommation du mois</h2>
                </div>
                <label class="block-footer">{{monthValue | number: '1.0-2'}} <small>{{monthEnergyDetails?.unit}}</small></label>
            </div>
            <div class="block year">
                <div class="block-header">
                    <h2>Consommation annuelle</h2>
                </div>
                <label class="block-footer">{{yearValue | number: '1.0-2'}}
                    <small>{{yearEnergyDetails?.unit}}</small></label>
            </div>
        </div>
        <div class="logo-container">
            <p>Cette centrale solaire photovoltaïque a été dimensionnée, installée par :</p>
            <img alt="logo" class="logo" src="../../../assets/logos/niroma-logo.png">
        </div>
    </div>
</div>
