export type TimeUnit = 'QUARTER_OF_AN_HOUR' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

export enum TIME_UNIT_ENUM {
    QUARTER_OF_AN_HOUR = 'QUARTER_OF_AN_HOUR',
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

