export type EnergyMeterType = 'Production' | 'Consumption' | 'SelfConsumption' | 'FeedIn' | 'Purchased';

export enum ENERGY_METER_TYPE_ENUM {

  Production = "Production",
  Consumption = "Consumption",
  SelfConsumption = "SelfConsumption",
  FeedIn = "FeedIn",
  Purchased = "Purchased"
}
