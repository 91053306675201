import {Component, OnDestroy, OnInit} from '@angular/core';
import {SolarEdgeService} from '../../services/solar-edge.service';
import {EnergyDetails} from '../../domain/energy-details';
import {EnvironmentBenefits} from '../../domain/environment-benefits';
import {PowerFlow} from '../../domain/power-flow';
import * as Moment from 'moment';
import {interval, Observable, Subscription} from 'rxjs';
import {ELEMENT_STATUS_ENUM} from '../../domain/element-status.type';

// Refresh period in ms
const REFRESH_PERIOD = 30000;

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {


    public dayEnergyDetails: EnergyDetails;
    public dayValue: number;

    public monthEnergyDetails: EnergyDetails;
    public monthValue: number;

    public yearEnergyDetails: EnergyDetails;
    public yearValue: number;

    public environmentBenefits: EnvironmentBenefits;

    public powerFlow: PowerFlow;
    private intervalObservable: Observable<number>;
    private intervalSubscription: Subscription;

    constructor(private solarEdgeService: SolarEdgeService) {
    }

    ngOnInit(): void {
        console.log("Fetch immediate data")
        // Fetch Environment Benefits
        this.solarEdgeService.getEnvBenefits().subscribe(value => this.environmentBenefits = value);

        // Fetch statistics
        this.solarEdgeService.getEnergyDetailsDaily().subscribe(value => {
            this.dayEnergyDetails = value;
            this.dayValue = this.dayEnergyDetails.extractConsumption()
        });
        this.solarEdgeService.getEnergyDetailsMonthly().subscribe(value => {
            this.monthEnergyDetails = value;
            this.monthValue = this.monthEnergyDetails.extractConsumption()
        });
        this.solarEdgeService.getEnergyDetailsYearly().subscribe(value => {
            this.yearEnergyDetails = value;
            this.yearValue = this.yearEnergyDetails.extractConsumption()

        });

        // Fetch live consumption
        this.solarEdgeService.getPowerFlow().subscribe(value => this.powerFlow = value);

        this.intervalObservable = interval(REFRESH_PERIOD);
        this.intervalSubscription = this.intervalObservable.subscribe(() => {
            console.log("Reload data at interval")
            this.solarEdgeService.getPowerFlow().subscribe(value => this.powerFlow = value);
        });
    }

    ngOnDestroy(): void {
        if (this.intervalObservable) {
            this.intervalSubscription.unsubscribe();
        }
    }

    public getCurrentMonth(): string {
        return Moment().locale('fr').format('MMMM');
    }

    public getStorageIllustration(): string {
        if (this.powerFlow && this.powerFlow.STORAGE.chargeLevel > 0)
            return 'storage_' + Math.floor(this.powerFlow.STORAGE.chargeLevel / 10) + '0';
        return '';
    }

    public getPvIllustration(): string {
        if (this.powerFlow
            && this.powerFlow.GRID
            && this.powerFlow.GRID.status === ELEMENT_STATUS_ENUM.ACTIVE) {
            return 'pv-on';
        }
        return 'pv-off';
    }

    public isStorageCharging(): boolean {
        return this.powerFlow
            && this.powerFlow.STORAGE
            && this.powerFlow.STORAGE.status === ELEMENT_STATUS_ENUM.CHARGING;
    }

    public isStorageDischarging(): boolean {
        return this.powerFlow
            && this.powerFlow.STORAGE
            && this.powerFlow.STORAGE.status === ELEMENT_STATUS_ENUM.DISCHARGING;
    }

    public isCommercialLoadActive(): boolean {
        return this.powerFlow
            && this.powerFlow.LOAD
            && this.powerFlow.LOAD.status === ELEMENT_STATUS_ENUM.ACTIVE;
    }

    public isGridActive(): boolean {
        return this.powerFlow
            && this.powerFlow.GRID
            && this.powerFlow.GRID.status === ELEMENT_STATUS_ENUM.ACTIVE
            && this.powerFlow.GRID.currentPower > 0;
    }

    public getCommercialLoadClass(): string {
        return this.powerFlow
        && this.powerFlow.LOAD
        && this.powerFlow.LOAD.status === ELEMENT_STATUS_ENUM.ACTIVE ? 'active' : 'idle';
    }

    public isStorageAvailable() {
        return this.powerFlow
            && this.powerFlow.STORAGE;
    }

    public isVehicleAvailable() {
        return this.powerFlow
            && this.powerFlow.EV;
    }
}

