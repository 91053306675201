<ng-select
        [name]="'customerSelect'"
        [items]="customerTypes"
        [bindLabel]="'type'"
        [clearable]="false"
        [searchable]="false"
        [disabled]="disabled"
        [ngModel]="customerType"
        [placeholder]="placeholder"
        (ngModelChange)="onChange($event)">
</ng-select>
