import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomerConfiguration} from '../../../domain/customer-configuration';
import {CustomerConfigurationService} from '../../../services/customer-configuration.service';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomerSelectComponent),
    multi: true
};
@Component({
    selector: 'customer-select',
    templateUrl: './customer-select.component.html',
    styleUrls: ['./customer-select.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class CustomerSelectComponent implements OnInit, ControlValueAccessor {

    @Input()
    public placeholder: string;
    public customerConfigurations: Array<CustomerConfiguration> = [];
    public customerConfiguration: CustomerConfiguration;
    public disabled: boolean;

    private onNgChange: (customerConfiguration: CustomerConfiguration) => void;
    private onNgTouched: () => void;

    constructor(private customerConfigurationService: CustomerConfigurationService) {
    }

    ngOnInit() {
        this.customerConfigurationService.findAll().subscribe(customerConfigurations => {
            this.customerConfigurations = customerConfigurations;
            this.writeValue(this.customerConfiguration);
        });
    }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(customerConfiguration: CustomerConfiguration): void {
        this.customerConfiguration = customerConfiguration;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onChange(customerConfiguration: CustomerConfiguration): void {
        this.customerConfiguration = customerConfiguration;
        this.onNgChange(customerConfiguration);
    }
}
